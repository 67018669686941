import { format } from 'date-fns'
import { pythonApi } from '../../../app/services/api/api'
import { downloadFile } from '../../../utils/helpers'
import { prepareFilters } from './helpers'
import { ApiResponse, AlarmTypesResponse, Alarm, AlarmQueryParams, PhysDevicesResponse } from './types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAlarms: builder.query<ApiResponse, any>({
      query: ({ filters }) => {
        const searchParams = prepareFilters(filters)
        if (filters.active_tab !== 0) {
          return { url: `/alarms-trigger/?${searchParams}`, method: 'GET' }
        }
        return { url: `/alarms/?${searchParams}`, method: 'GET' }
      },
    }),
    getAllAlarmTypes: builder.query<AlarmTypesResponse, void>({
      query: () => {
        return { url: `/alarms-type/`, method: 'GET' }
      },
    }),
    getAllPhysicalDevices: builder.query<PhysDevicesResponse, any>({
      query: (props) => {
        const queryParams = new URLSearchParams()
        if (props.location) {
          queryParams.set('location', props.location)
        }
        if (props.group) {
          queryParams.set('group', props.group)
        }
        if (props.measurement) {
          queryParams.set('measurement', props.measurement)
        }
        return { url: `/physical-devices/?${queryParams.toString()}`, method: 'GET' }
      },
    }),
    getAlarmById: builder.query<Alarm, { id: number }>({
      query: ({ id }) => ({
        url: `/alarms/${id}`,
        method: 'GET',
      }),
    }),
    createNewAlarm: builder.mutation<Alarm, AlarmQueryParams>({
      query: (body) => ({
        url: `/alarms/`,
        method: 'POST',
        body: body,
      }),
    }),

    patchAlarmById: builder.mutation<Alarm, { id: number; body: Partial<Alarm> }>({
      query: ({ id, body }) => ({
        url: `/alarms/${id}/`,
        method: 'PATCH',
        body,
      }),
    }),
    exportCSV: builder.mutation<void, { filters: any }>({
      query: ({ filters }) => {
        const searchParams = prepareFilters(filters)
        return {
          url: `/alarms/export_alarms/?${searchParams}`,
          method: 'POST',
          responseHandler: async (response) => {
            if (response.ok) {
              const href = window.URL.createObjectURL(await response.blob())
              const filename = `Alarms_${format(new Date(), 'yyyy-MM-dd')}.csv`
              downloadFile(href, filename)
            }
            // Return null or an empty object to ensure the state remains serializable
            return null
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAlarmsQuery,
  useCreateNewAlarmMutation,
  useGetAllAlarmTypesQuery,
  useGetAllPhysicalDevicesQuery,
  useGetAlarmByIdQuery,
  usePatchAlarmByIdMutation,
  useExportCSVMutation,
} = extendedApi
