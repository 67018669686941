import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  Flex,
  Spinner,
  Text,
  Grid,
  Accordion,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
} from '@chakra-ui/react'
import {
  useGetAllAlarmsQuery,
  useExportCSVMutation,
  usePatchAlarmByIdMutation,
} from '../api/alarmsApi'
import Header from '../../../app/components/Header'
import CustomTabs from '../../../app/components/CustomTabs/CustomTabs'
import { CustomAccordionItem } from '../components/CustomAccordionItem'
import FilterIcon from '../../../assets/icons/Filter'
import { Download } from '../../../assets/icons/Download'
import Search from '../../../assets/icons/Search'
import FilterModal from '../components/FilterModal'
import { format } from 'date-fns'
import { Pagination } from '../../../app/components/Pagination'
import { useLocation } from 'react-router-dom'
import CreateAlarmModal from '../components/CreateAlarmModal'

export const COLUMN_NAME = {
  "0": "Created at",
  "1": "Triggered at",
  "2": "Resolved at",
}

const debounce = (func, delay) => {
  let timeoutId
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const Alarms: React.FC<{ locations: any }> = ({ locations }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const location = useLocation()

  const pageNumber = Number(new URLSearchParams(location.search).get('page') || 1)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined)
  const [filters, setFilters] = useState<{
    from_date?: Date
    to_date?: Date
    severity: string[]
    location?: number
    value_comparison?: string
    value?: number
  }>({
    from_date: undefined,
    to_date: undefined,
    severity: undefined,
    location: undefined,
    value_comparison: undefined,
    value: undefined,
  })

  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch: refetchAllAlarms,
  } = useGetAllAlarmsQuery({
    filters: {
      ...filters,
      from_date: filters.from_date ? format(filters.from_date, 'yyyy-MM-dd') : undefined,
      to_date: filters.to_date ? format(filters.to_date, 'yyyy-MM-dd') : undefined,
      is_resolved: activeIndex === 2,
      active_tab: activeIndex,
      q: searchInput,
      page: pageNumber,
    },
  })

  const totalPages = data?.total_pages

  const handleFilterModalOpen = useCallback(() => setIsFilterModalOpen(true), [])
  const handleFilterModalClose = useCallback(() => setIsFilterModalOpen(false), [])

  const results = useMemo(() => data?.results, [data])

  const tabs = useMemo(() => [{ label: 'My Alarms' }, { label: 'Active' }, { label: 'Past' }], [])

  const debouncedChangeHandler = debounce((value) => {
    setSearchInput(value)
  }, 300)

  const handleInputChange = (event) => {
    const value = event.target.value
    debouncedChangeHandler(value)
  }

  const filtersCounter = useMemo(() => {
    const nonEmptyFilters = Object.keys(filters).filter(
      (k) => filters[k] !== undefined && filters[k] !== '',
    )
    let difference = 0
    if (
      nonEmptyFilters.includes('value') &&
      nonEmptyFilters.includes('value_comparison')
    ) {
      difference += 1
    }

    if (nonEmptyFilters.includes('severity') && filters.severity.length === 0) {
      difference += 1
    }

    if (nonEmptyFilters.includes('from_date') && nonEmptyFilters.includes('to_date')) {
      difference += 1
    }
    return nonEmptyFilters.length - difference
  }, [filters])

  const [exportAsCSV, { isLoading: isExportAsCSVLoading }] = useExportCSVMutation()
  const [patchAlarmById, { isLoading: isPatchAlarmLoading }] = usePatchAlarmByIdMutation()
  if (error) {
    return <Text color="red.500">Failed to load alarms</Text>
  }

  return (
    <>
      <CreateAlarmModal
        isOpen={isCreateModalOpen}
        handleClose={() => setIsCreateModalOpen(false)}
        filters={filters}
        refetchAllAlarms={refetchAllAlarms}
      />
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={handleFilterModalClose}
        locations={locations}
        setFilters={setFilters}
        filters={filters}
      />
      <Header title="Alarms" />

      <Flex justifyContent="space-between">
        <CustomTabs
          tabs={tabs}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
        <Flex gap="2">
          <Button
            width="200px"
            colorScheme='button.primary'
            onClick={() => setIsCreateModalOpen(true)}
          >
            Create alarm
          </Button>
          <Button
            variant="ghost"
            color="icon.primary"
            onClick={() => handleFilterModalOpen()}
          >
            <Box>
              <FilterIcon boxSize="24px" color="icon.primary" />
              {filtersCounter ? (
                <Badge
                  position="absolute"
                  top="0"
                  right="0"
                  transform="translate(-20%, 5%)"
                  bg="blue.500"
                  color="white"
                  borderRadius="full"
                  px="6px"
                  py="2px"
                  fontSize="12px"
                >
                  {filtersCounter}
                </Badge>
              ) : null}
            </Box>
          </Button>
          <Button
            variant="ghost"
            color="icon.primary"
            onClick={() =>
              exportAsCSV({
                filters: {
                  ...filters,
                  from_date: filters.from_date
                    ? format(filters.from_date, 'yyyy-MM-dd')
                    : undefined,
                  to_date: filters.to_date
                    ? format(filters.to_date, 'yyyy-MM-dd')
                    : undefined,
                  is_active: activeIndex === 0 ? 1 : 0,
                  q: searchInput,
                  page: pageNumber,
                },
              })
            }
          >
            <Download boxSize="24px" color="icon.primary" />
          </Button>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search color="#9B9B9B" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search"
              onChange={handleInputChange}
              bg="white"
              border="1px solid transparent"
              borderRadius="full"
              _placeholder={{ color: 'gray.400' }}
              _focus={{
                boxShadow: '0 0 0 1px text.dark',
                border: '1px solid',
                borderColor: 'text.dark',
              }}
            />
          </InputGroup>
        </Flex>
      </Flex>

      <Flex
        alignItems="flex-end"
        flexWrap="wrap-reverse"
        w="full"
        gap={6}
        bg="white"
        rounded="xl"
      >
        {isLoading || isFetching ? (
          <Flex h="80vh" w="100%" justifyContent="space-around" align="center">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Box w="100%" bg="white" borderRadius="md" boxShadow="md">
            <Grid templateColumns="repeat(6, 1fr)" gap={6} p={4}>
              <Text fontSize="14px" fontWeight="700" color="text.light" textAlign="left">
                Alarm
              </Text>
              <Text fontSize="14px" fontWeight="700" color="text.light">
                Type
              </Text>
              <Text fontSize="14px" fontWeight="700" color="text.light">
                {COLUMN_NAME[activeIndex]}
              </Text>
              <Text fontSize="14px" fontWeight="700" color="text.light" textAlign="left">
                Severity
              </Text>
              <Text fontSize="14px" fontWeight="700" color="text.light">
                Devices
              </Text>
              <Text fontSize="14px" fontWeight="700" color="text.light">
                Actions
              </Text>
            </Grid>

            <Accordion allowToggle outline="none" boxShadow="none">
              {results.map((alarm, index) => (
                <CustomAccordionItem
                  index={index}
                  activeIndex={activeIndex}
                  alarm={alarm}
                  patchAlarmById={patchAlarmById}
                  refetchAllAlarms={refetchAllAlarms}
                />
              ))}
            </Accordion>

            {totalPages > 1 ? (
              <Pagination totalPages={totalPages} currentPage={pageNumber} />
            ) : null}
          </Box>
        )}
      </Flex>
    </>
  )
}

export { Alarms }
