import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Flex,
  Grid,
  Text,
  Tooltip,
  Tag,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useCallback, useState } from 'react'
import { Label } from '../../../app/components/Label/Label'
import { CheckCircleIcon } from '../../../assets/icons/CheckCircle'
import Info from '../../../assets/icons/Info'
import { EditIcon } from '@chakra-ui/icons'
import { AccordionPanelAlarms } from './AccordionPanelAlarms'

export const formatDate = (date) => {
  try {
    return date ? format(date, 'yyyy-MM-dd HH:mm:ss') : "-";
  } catch (error) {
    console.error("Error formatting date:", error);
    return "-"
  }
}

export const CustomAccordionItem = ({
  index,
  activeIndex,
  alarm,
  patchAlarmById,
  refetchAllAlarms,
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false)

  const setNotActive = useCallback(() => {
    patchAlarmById({ id: alarm.id, body: { is_active: false } })
  }, [alarm.id, patchAlarmById])

  const dateMapping = {
    0: alarm?.created_at,
    1: alarm?.triggered_at,
    2: alarm?.resolved_at
  }

  return (
    <AccordionItem outline="none" boxShadow="none">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            outline="none"
            boxShadow="none"
            px="0"
            sx={{
              outline: 'none',
              _hover: { bg: isExpanded ? 'none' : 'gray.100' },
              _focus: { boxShadow: 'none' },
              transitionDuration: '0.3s',
            }}
          >
            <Grid templateColumns="repeat(6, 1fr)" gap={6} p={4} key={index} w="full">
              <Flex align="center">
                <AccordionIcon mr="4" />
                <Flex direction="column" textAlign="left">
                  <Text fontSize="14px" fontWeight="700" color="text.dark">
                    {alarm?.name}
                  </Text>
                </Flex>
              </Flex>
              <Flex align="center">
                <Tag variant="subtle" colorScheme='cyan'>
                  {alarm?.type.name}
                </Tag>
              </Flex>
              <Flex align="center">
                <Text
                  fontSize="12px"
                  fontWeight="400"
                  color="text.light"
                  textAlign="left"
                >
                  {formatDate(dateMapping[activeIndex])}
                </Text>
              </Flex>
              <Flex align="center">
                <Text textAlign="left">
                  <Label type={alarm.severity} />
                </Text>
              </Flex>
              <Flex align="center">
                <Text fontSize="14px" fontWeight="700" color="text.dark" textAlign="left">
                  {alarm?.devices ? alarm?.devices?.map((device) => device.title).join(", ") : "-"}
                </Text>
              </Flex>
              <Flex gap="2">
                {activeIndex === 1 && (
                  <>
                    <Tooltip
                      hasArrow
                      rounded="lg"
                      label={
                        <Box pl="16px" p="12px">
                          <Text fontSize="14px" fontWeight="700" color="white">
                            {alarm?.description || "Please provide how to resolve the alarm"}
                          </Text>
                        </Box>
                      }
                      placement="left"
                      bg="icon.strong"
                    >
                      <Flex
                        color="icon.strong"
                        bg="#F7F9FD"
                        rounded="lg"
                        align="center"
                        justifyContent="space-around"
                        boxSize="32px"
                        _hover={{ bg: '#E8EEFD' }}
                      >
                        <Info />
                      </Flex>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      rounded="md"
                      placement="bottom"
                      bg="heading.dark"
                      label={<Text p="4px">Mark as resolved</Text>}
                    >
                      <Flex
                        color="icon.strong"
                        bg="#F7F9FD"
                        rounded="lg"
                        align="center"
                        justifyContent="space-around"
                        boxSize="32px"
                        _hover={{ bg: '#E8EEFD' }}
                      >
                        <CheckCircleIcon
                          as="button"
                          onClick={(e) => {
                            e.stopPropagation()
                            setNotActive()
                            refetchAllAlarms()
                          }}
                        />
                      </Flex>
                    </Tooltip>
                  </>
                )}
                {activeIndex === 0 && (
                  <Tooltip
                    hasArrow
                    rounded="lg"
                    label={
                      <Box pl="16px" p="12px">
                        <Text fontSize="14px" fontWeight="700" color="white">
                          Edit will be implemented in the next sprint!
                        </Text>
                      </Box>
                    }
                    placement="left"
                    bg="icon.strong"
                  >
                    <Flex
                      color="icon.strong"
                      bg="#F7F9FD"
                      rounded="lg"
                      align="center"
                      justifyContent="space-around"
                      boxSize="32px"
                      _hover={{ bg: '#E8EEFD' }}
                    >
                      <EditIcon />
                    </Flex>
                  </Tooltip>
                )}

              </Flex>
            </Grid>
          </AccordionButton>
          <AccordionPanelAlarms alarm={alarm} activeTab={activeIndex} />
        </>
      )}
    </AccordionItem>
  )
}
