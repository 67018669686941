import { useForm } from "react-hook-form";
import CustomModal from "../../reports/pages/components/CustomModal";
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Severity from "./Severity";
import SingleSelect from "../../reports/components/SingleSelect";
import { Flex, Input, Checkbox, HStack } from "@chakra-ui/react";
import { useGetAllLocationsQuery } from "../../reports/api/reportsApi";
import MultiSelect from "../../reports/components/MultiSelect";
import { useGetDeviceGroupListQuery } from "../../deviceConfiguration/api/deviceGroupConfigurationApi";
import { useGetMeasurementTypesQuery } from "../../locations/api/locationsApi";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import EmailInput from "../../reports/pages/components/EmailInput";
import MultistateAlarm from "./AlarmTypes/MultistateAlarm";
import { useCreateNewAlarmMutation, useGetAllAlarmTypesQuery, useGetAllPhysicalDevicesQuery } from "../api/alarmsApi";
import LevelAlarm from "./AlarmTypes/LevelAlarm";

const ALARM_NOTIFY = [
    { id: 1, name: 'Viber' },
    { id: 2, name: 'Mail' },
]

export default function CreateAlarmModal({ isOpen, handleClose, filters, refetchAllAlarms }) {
    const {
        handleSubmit,
        watch,
        setValue,
        register,
        reset
    } = useForm()

    const [isChecked, setIsChecked] = useState(true)
    const [notifyWhenActive, setNotifyWhenActive] = useState(true)
    const [hours, setHours] = useState({ from: "", to: "" })
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [emails, setEmails] = useState([]);
    const [createAlarm] = useCreateNewAlarmMutation()

    const selectedAlarmType = watch('selectedAlarmType') || null
    const selectedAlarmNotify = watch('selectedAlarmNotify') || null
    const selectedMeasurementType = watch('selectedMeasurementType') || null
    const severity = watch('severity') || filters.severity || []
    const selectedLocations = watch('selectedLocations') || []
    const selectedDevicesGroups = watch('selectedDevicesGroups') || []
    const selectedDevices = watch('selectedDevices') || []

    const { data: alarmTypes } = useGetAllAlarmTypesQuery(undefined, { skip: !selectedMeasurementType });
    const { data: locations } = useGetAllLocationsQuery({ measurementTypeId: selectedMeasurementType }, { skip: !selectedMeasurementType });
    const { data: measurementTypes } = useGetMeasurementTypesQuery({})
    const { data: devicesGroups } = useGetDeviceGroupListQuery({ measurementTypeId: selectedMeasurementType }, { skip: !selectedMeasurementType });
    const { data: userPhysDevices } = useGetAllPhysicalDevicesQuery(
        {
            location: selectedLocations,
            group: selectedDevicesGroups,
            measurement: selectedMeasurementType,
        },
        { skip: !selectedMeasurementType || selectedLocations.length === 0 }
    );

    const setSelectedAlarmType = (selectedAlarmType: number) => {
        setValue('selectedAlarmType', selectedAlarmType)
    }
    const setSelectedMeasurementType = (selectedMeasurementType: number) => {
        setValue('selectedMeasurementType', selectedMeasurementType)
    }
    const setSelectedAlarmNotify = (selectedAlarmNotify: number) => {
        setValue('selectedAlarmNotify', selectedAlarmNotify)
        setEmails([])
        setPhoneNumber(null)
    }
    const setSelectedLocations = (selectedLocations: string[]) => {
        setValue('selectedLocations', selectedLocations)
    }
    const setSelectedDevicesGroups = (selectedDevicesGroups: string[]) => {
        setValue('selectedDevicesGroups', selectedDevicesGroups)
    }
    const setSelectedDevices = (selectedDevices: string[]) => {
        setValue('selectedDevices', selectedDevices)
    }

    useEffect(() => {
        setValue(
            'selectedLocations',
            locations?.results?.map((location: any) => location.id.toString()),
        )
    }, [locations, setValue])

    const alarmTypesSelect = useMemo(
        () =>
            alarmTypes?.results.map(({ id, name }) => ({
                id,
                name,
            })),
        [alarmTypes],
    )

    const alarmNotifySelect = ALARM_NOTIFY?.map(({ id, name }) => ({
        id,
        name,
    }))

    const devicesGroupsSelect = useMemo(
        () =>
            devicesGroups?.map(({ id, name }) => ({
                id,
                name,
            })),
        [devicesGroups],
    )

    const userDevicesSelect = useMemo(
        () =>
            userPhysDevices?.results?.map(({ id, title }) => ({
                id,
                name: title,
            })),
        [userPhysDevices],
    )

    const measurementTypesSelect = useMemo(
        () =>
            measurementTypes?.results.map(({ id, name }) => ({
                id,
                name,
            })),
        [measurementTypes],
    )

    const locationSelect = locations?.results?.map((x) => {
        return { id: x.id, name: x.title }
    })

    const setSelectedSeverity = useCallback(
        (severity: string[]) => {
            setValue('severity', severity)
        },
        [setValue],
    )

    const handleHoursChange = (e) => {
        const { name, value } = e.target
        setHours((prev) => ({ ...prev, [name]: value }))
    }

    const handleCreate = async () => {
        const requestParams = {
            type_id: watch("selectedAlarmType"),
            devices: selectedDevices,
            severity: watch("severity")[0],
            name: watch("alarmName"),
            notification: {
                viber: phoneNumber,
                emails: emails,
            },
            active_hours: hours,
            description: watch("resolveAlarm"),
        }
        if (selectedAlarmType === 1) {
            requestParams['comparison_operator'] = watch('value_comparison')
            requestParams['comparison_value'] = watch('value')
        } else if (selectedAlarmType === 2) {
            requestParams['status'] = notifyWhenActive
        } else {
            requestParams['multistate_fields'] = watch("selectedStateFields")
        }
        await createAlarm(requestParams)
        handleClose()
        refetchAllAlarms()
    }

    return (
        <CustomModal
            title="Create new alarm"
            tooltipLabel="Please populate the required fields first"
            handleSend={handleSubmit(() => handleCreate())}
            isDisabled={
                severity.length === 0 ||
                !watch("alarmName") ||
                !watch('resolveAlarm') ||
                selectedMeasurementType === null ||
                selectedDevices.length === 0 ||
                selectedAlarmType === null ||
                selectedAlarmNotify === null ||
                (selectedAlarmNotify === 1 && phoneNumber?.length < 12) ||
                (selectedAlarmNotify === 2 && emails?.length === 0) ||
                (selectedAlarmType === 1 && (!watch('value_comparison') || !watch('value'))) ||
                (selectedAlarmType === 3 && !watch("selectedState")) ||
                (!isChecked && (hours?.to === "" || hours?.from === ""))
            }
            isLoading={false}
            isOpen={isOpen}
            onClose={() => {
                handleClose()
                reset()
            }}
            buttonName="Create"
        >
            <Flex direction="column" gap="20px">
                <Severity severity={severity} setSelectedSeverity={setSelectedSeverity} selectType="single" />
                <Input
                    w="full"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor={watch('alarmName') ? 'border.strong' : 'red.500'}
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id='alarmName'
                    {...register('alarmName', { required: true })}
                    placeholder="Enter alarm name"
                />
                <Input
                    w="full"
                    type="text"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor={watch('resolveAlarm') ? 'border.strong' : 'red.500'}
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id='resolveAlarm'
                    {...register('resolveAlarm', { required: true })}
                    placeholder="How to resolve the alarm?"
                />
                <SingleSelect
                    key="measurement-types"
                    items={measurementTypesSelect}
                    isDisabled={false}
                    {...register('selectedMeasurementTypesSelect')}
                    setSelectedOption={setSelectedMeasurementType}
                    selectedOption={selectedMeasurementType}
                    required={selectedMeasurementType === null}
                    header="Select measurement type"
                />
                {selectedMeasurementType && (
                    <>
                        <MultiSelect
                            items={locationSelect}
                            setSelectedOptions={setSelectedLocations}
                            selectedOptions={selectedLocations}
                            invalidateFields={['selectedDevices']}
                            setValue={setValue}
                            header={`Select location/s${selectedLocations.length > 0 ? ` (${selectedLocations.length})` : ''}`}
                        />
                        <MultiSelect
                            items={devicesGroupsSelect}
                            setSelectedOptions={setSelectedDevicesGroups}
                            selectedOptions={selectedDevicesGroups}
                            invalidateFields={['selectedDevices']}
                            setValue={setValue}
                            header={`Select device group/s${selectedDevicesGroups.length > 0
                                ? ` (${selectedDevicesGroups.length})`
                                : ''
                                }`}
                        />
                        <MultiSelect
                            items={userDevicesSelect}
                            setSelectedOptions={setSelectedDevices}
                            selectedOptions={selectedDevices}
                            required={selectedDevices.length === 0}
                            header={`Select device/s${selectedDevices.length > 0 ? ` (${selectedDevices.length})` : ''}`}
                        />
                        <SingleSelect
                            key="alarm-type"
                            items={alarmTypesSelect}
                            isDisabled={false}
                            {...register('selectedAlarmType')}
                            setSelectedOption={setSelectedAlarmType}
                            selectedOption={selectedAlarmType}
                            required={selectedAlarmType === null}
                            header="Select alarm type"
                        />
                        {selectedAlarmType === 1 && <LevelAlarm watch={watch} setValue={setValue} register={register} />}
                        {selectedAlarmType === 2 &&
                            <Checkbox isChecked={notifyWhenActive} onChange={() => { setNotifyWhenActive(!notifyWhenActive) }}>
                                Trigger alarm when device status is <strong>{notifyWhenActive ? "true" : "false"}</strong>
                            </Checkbox>
                        }
                        {selectedAlarmType === 3 && <MultistateAlarm watch={watch} setValue={setValue} register={register} />}
                        <SingleSelect
                            key="alarm-notify"
                            items={alarmNotifySelect}
                            isDisabled={false}
                            {...register('selectedAlarmNotify')}
                            setSelectedOption={setSelectedAlarmNotify}
                            selectedOption={selectedAlarmNotify}
                            required={selectedAlarmNotify === null}
                            header="Notify on"
                        />
                        {selectedAlarmNotify === 1 &&
                            <PhoneInput
                                inputProps={{
                                    maxLength: 16,
                                }}
                                inputStyle={{ width: "100%" }}
                                countryCodeEditable={false}
                                country={'bg'}
                                onlyCountries={['bg']}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e)}
                            />
                        }
                        {selectedAlarmNotify === 2 && <EmailInput emails={emails} setEmails={setEmails} />}
                        <Checkbox isChecked={isChecked} onChange={() => {
                            setIsChecked(!isChecked)
                            setHours({ from: "", to: "" })
                        }}>24/7 (The alarm will be active every day)</Checkbox>
                        {!isChecked && (
                            <HStack spacing={4} align="center">
                                <Input
                                    type="time"
                                    name="from"
                                    value={hours.from}
                                    onChange={handleHoursChange}
                                    borderColor={hours.from ? "border.strong" : "red.500"}
                                />
                                <Flex align="center" justify="center" fontWeight="bold" fontSize="lg">
                                    -
                                </Flex>
                                <Input
                                    type="time"
                                    name="to"
                                    value={hours.to}
                                    onChange={handleHoursChange}
                                    borderColor={hours.to ? "border.strong" : "red.500"}
                                />
                            </HStack>
                        )}
                    </>
                )}
            </Flex>
        </CustomModal>
    )
}