import {
    AccordionPanel,
    Flex,
    Text,
    Divider,
    Box,
    Alert,
    AlertIcon,
} from '@chakra-ui/react'
import React from 'react'
import { formatDate } from './CustomAccordionItem';
import { COLUMN_NAME } from '../pages/Alarms';

export const AccordionPanelAlarms = ({ alarm, activeTab }) => {
    const multistateFields = Array.isArray(alarm?.multistate_alarm?.multistate_fields) ? alarm?.multistate_alarm?.multistate_fields : [];

    const dateMapping = {
        0: alarm?.created_at,
        1: alarm?.triggered_at,
        2: alarm?.resolved_at
    }

    return (
        <AccordionPanel pb={4} bg="gray.100" borderRadius="md">
            <Flex direction="column" p={4}>

                {/* Multistate Type */}
                {alarm?.type.name === "Multistate" && (
                    <>
                        <Box mb={4} p={3} bg="blue.50" borderRadius="md">
                            <Text fontWeight="bold">{COLUMN_NAME[activeTab]}</Text>
                            <Text>{formatDate(dateMapping[activeTab])}</Text>
                        </Box>

                        {alarm.description ? (
                            <Box mb={4} p={3} bg="blue.50" borderRadius="md">
                                <Text fontWeight="bold">How to resolve the alarm:</Text>
                                <Text>{alarm.description}</Text>
                            </Box>
                        ) : (
                            <Alert status="info" mb={4}>
                                <AlertIcon />
                                No resolution instructions provided.
                            </Alert>
                        )}

                        {multistateFields.length > 0 ? (
                            multistateFields.map((record, index) => (
                                <Box key={record.id} p={3} bg="white" borderRadius="md" boxShadow="sm" mb={2}>
                                    <Flex gap={3}>
                                        <Text fontWeight="medium">Value:</Text>
                                        <Text>{record.value}</Text>
                                    </Flex>
                                    <Flex gap={3}>
                                        <Text fontWeight="medium">Description:</Text>
                                        <Text>{record.description}</Text>
                                    </Flex>
                                    {index < multistateFields.length - 1 && (
                                        <Divider borderWidth="1px" opacity={1} my={2} />
                                    )}
                                </Box>
                            ))
                        ) : (
                            <Alert status="warning">
                                <AlertIcon />
                                No multi-state fields available.
                            </Alert>
                        )}
                    </>
                )}

                {/* Level Type */}
                {alarm?.type.name === "Level" && (
                    <>
                        <Box mb={4} p={3} bg="blue.50" borderRadius="md">
                            <Text fontWeight="bold">{COLUMN_NAME[activeTab]}</Text>
                            <Text>{formatDate(dateMapping[activeTab])}</Text>
                        </Box>

                        <Box mb={4} p={3} bg="white" borderRadius="md" boxShadow="sm">
                            <Flex gap={3}>
                                <Text fontWeight="medium">Comparison Operator:</Text>
                                <Text>{alarm?.level_alarm.comparison_operator}</Text>
                            </Flex>
                            <Flex gap={3}>
                                <Text fontWeight="medium">Comparison Value:</Text>
                                <Text>{alarm?.level_alarm.comparison_value}</Text>
                            </Flex>
                        </Box>
                    </>
                )}

                {/* Status Type */}
                {alarm?.type.name === "Status" && (
                    <>
                        <Box mb={4} p={3} bg="blue.50" borderRadius="md">
                            <Text fontWeight="bold">{COLUMN_NAME[activeTab]}</Text>
                            <Text>{formatDate(dateMapping[activeTab])}</Text>
                        </Box>
                        <Box mb={4} p={3} bg="white" borderRadius="md" boxShadow="sm">
                            <Text fontWeight="bold">Status:</Text>
                            <Text>{alarm?.status_alarm.status ? "Active" : "Inactive"}</Text>
                        </Box>
                    </>
                )}
            </Flex>
        </AccordionPanel>
    )
}
