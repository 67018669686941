import React from "react"
import SingleSelect from "../../../reports/components/SingleSelect"
import { HStack, Input, Button, Box } from "@chakra-ui/react"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"

const STATES = [
    { id: 1, name: "Integer" },
    { id: 2, name: "Bit" },
]

export default function MultistateAlarm({ watch, setValue, register }) {
    const selectedState = watch("selectedState") || null
    const selectedStateFields = watch("selectedStateFields") || [{ id: 1, value: null, description: "" }]

    const statesSelect = STATES?.map(({ id, name }) => ({
        id,
        name,
    }))

    const setSelectedState = (selectedState) => {
        setValue("selectedState", selectedState)
        setValue("selectedStateFields", [{ id: 1, value: null, description: "" }])
    }

    const handleAddField = () => {
        setValue(
            "selectedStateFields",
            [
                ...selectedStateFields,
                { id: selectedStateFields.length + 1, value: null, description: "" },
            ])
    }

    const handleRemoveField = (index) => {
        const updatedFields = selectedStateFields.filter((_, i) => i !== index)
        setValue("selectedStateFields", updatedFields)
    }

    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...selectedStateFields]
        updatedFields[index][field] = value
        setValue("selectedStateFields", updatedFields)
    }

    return (
        <>
            <SingleSelect
                key="states"
                items={statesSelect}
                isDisabled={false}
                {...register("selectedState")}
                setSelectedOption={setSelectedState}
                selectedOption={selectedState}
                required={selectedState === null}
                header="Select state"
            />
            {selectedState && (
                <Box>
                    {selectedStateFields.map((field, index) => (
                        <HStack key={field.id} spacing={4} align="center" mb={2}>
                            <Input
                                type={selectedState === 1 ? "number" : "text"}
                                name={`value-${index}`}
                                value={field.value}
                                onChange={(e) => handleFieldChange(index, "value", e.target.value)}
                                placeholder={selectedState === 1 ? "Integer (0-65535)" : "Bit (bit1-bit16)"}
                                borderColor={field.value ? 'border.strong' : 'red.500'}
                            />
                            <Input
                                name={`description-${index}`}
                                value={field.description}
                                onChange={(e) => handleFieldChange(index, "description", e.target.value)}
                                placeholder="Description"
                                borderColor={field.description ? 'border.strong' : 'red.500'}
                            />
                            <Button onClick={handleAddField} colorScheme="teal" size="sm">
                                <AddIcon />
                            </Button>
                            <Button disabled={selectedStateFields?.length === 1} onClick={() => handleRemoveField(field.id)} colorScheme="red" size="sm">
                                <MinusIcon />
                            </Button>
                        </HStack>
                    ))}
                </Box>
            )}
        </>
    )
}
