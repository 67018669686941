import { Flex, Input, Text } from "@chakra-ui/react"
import React from "react"
import SingleSelect from "../../../reports/components/SingleSelect"
import { comparisonsArr } from "../../../analysis/utils"

export default function LevelAlarm({ watch, setValue, register }) {
    const value_comparison = watch('value_comparison') || null

    return (
        <Flex direction="column" gap="1">
            <Flex justify="space-between" gap="2" align="center">
                <SingleSelect
                    header="Select a comparison"
                    items={comparisonsArr}
                    required={value_comparison === null}
                    setSelectedOption={(selectedOptionId: string) =>
                        setValue('value_comparison', selectedOptionId)
                    }
                    selectedOption={value_comparison}
                />
                <Text>-</Text>
                <Input 
                    {...register('value')}
                    type="number"
                    placeholder="Value"
                    borderColor={value_comparison === null ? 'border.strong' : 'red.500'}
                />
            </Flex>
        </Flex>
    )
}